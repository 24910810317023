<template>
  <el-form
    ref="ruleForm"
    :model="ruleForm"
    :rules="rules"
    label-width="0px"
    @keyup.enter.native="submitForm('ruleForm')"
  >
    <div class="login-box-par">
      <div class="login-box">
        <div class="login-tabs-box">ERP帐号登录</div>
        <div v-show="errorText" class="tip-error">
          <label>{{ errorText }}</label>
        </div>
        <div class="login-box-account">
          <div>
            <div>
              <el-form-item class="login-input" prop="companyName">
                <label class="login-label">公司名</label>
                <el-input
                  ref="companyName"
                  v-model="ruleForm.companyName"
                  :clearable="false"
                  placeholder="请输入公司名称"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-office-building"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item class="login-input" prop="account">
                <label class="login-label">账号</label>
                <el-input
                  ref="account"
                  v-model="ruleForm.account"
                  clearable
                  placeholder="请输入账号"
                >
                  <i slot="prefix" class="el-input__icon el-icon-user"></i>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item class="login-input" prop="pwd">
              <label class="login-label">密码</label>
              <el-input
                ref="password"
                v-model="ruleForm.pwd"
                placeholder="请输入密码"
                show-password
                type="password"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
          </div>
          <el-form-item class="login-input login-button">
            <el-button
              :loading="loading"
              class="submit-login"
              type="warning"
              @click="submitForm('ruleForm')"
              >登录</el-button
            >
          </el-form-item>
          <el-form-item class="login-checkbox">
            <el-checkbox v-model="remCompanyName" @change="onRemCompanyName"
              >记住公司名</el-checkbox
            >
            <el-checkbox v-model="remAccount" @change="onRemAccount"
              >记住账号</el-checkbox
            >

            <span class="p-login_resetPassword" @click="onResetPassword"
              >忘记密码</span
            >
            <span class="p-login_register" @click="onRegister">立即注册</span>
          </el-form-item>
        </div>

        <div class="login-box-wechat">
          <div class="login-wechat qr-code-box">
            <div>
              <img :src="QRCodeUrl" alt="" />
            </div>
          </div>
          <div class="wx-login-word">
            微信扫码快速登录</div>
        </div>

      </div>
    </div>
  </el-form>
</template>

<script>
import signalrWeChatLogin from "~plugins/vue-znl-ui/signalrWeChatLogin";
// import wechatQrcode from '~plugins/wechat/wechat-qrcode'

const CONFIG = {
  loginUrl: "Security/Login",
};

export default {
  mixins: [signalrWeChatLogin /*,wechatQrcode*/],
  data() {
    return {
      loading: false,
      rememberMe: false,
      rememberCompanyName: false,
      active: "company",
      sessionId: "",
      timer: null,
      errorText: "",
      sendSmsText: "发送验证码",
      ruleForm: {
        mobile: "",
        companyName: "",
        account: "",
        pwd: "",
        code: "",
        imgCode: "",
      },
      rules: {
        mobile: [{ required: true, message: "请输入手机号", trigger: "none" }],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "none" },
        ],
        account: [{ required: true, message: "请输入用户名", trigger: "none" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "none" }],
        // imgCode: [
        //   { required: true, message: '请输入图片验证码', trigger: 'none' }
        // ],
        // code: [
        //   { required: true, message: '请输入短信验证码', trigger: 'none' }
        // ]
      },
      redirect: undefined,
      otherQuery: null,
      imgUrl: "",
      remCompanyName: false,
      remAccount: false,
      QRCodeUrl: null,
    };
  },
  watch: {
    $route: {
      handler(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  computed: {
    showSendSmsCode() {
      return this.ruleForm.imgCode.length === 4;
    },
  },
  created() {
    this.onInit();
  },
  mounted() {},

  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    async submitForm(formName) {
      if (!this.hasValue(this.ruleForm.companyName)) {
        this.showError("请输入公司名称");
        this.$refs.companyName.focus();
        return false;
      }
      if (!this.hasValue(this.ruleForm.account)) {
        this.showError("请输入账号");
        this.$refs.account.focus();
        return false;
      }
      if (!this.hasValue(this.ruleForm.pwd)) {
        this.showError("请输入密码");
        this.$refs.password.focus();
        return false;
      }
      let param = {
        CompanyName: this.ruleForm.companyName,
        Account: this.ruleForm.account,
        Password: this.ruleForm.pwd,
        LoginType: 20, // Web 登录
      };

      this.loading = true;
      let isSuccess = false;
      let loginResult = {};
      await this.$post(CONFIG.loginUrl, param, (data, logic) => {
        console.log(data, logic);
        if (data && logic.code === 200) {
          loginResult = data;
          this.showSuccess("登录成功, 正在跳转");

          isSuccess = true;
        } else {
          let msg = (logic && logic.msg) || "登录失败，请重试";
          this.showError(msg);
        }
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });

      if (isSuccess) {
        await this.loginSuccess(loginResult);
      }
    },
    async loginSuccess(data) {
      await this.initLogin(data);
      this.$store.commit("setUseInfo", data);
      await this.$router.push("/");

      // 去除登录tabs页
      this.$store.commit("removeTab", "LoginView");
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },

    onRemCompanyName(val) {
      this.$nextTick(() => {
        this.onRemData("companyName", val);
      });
    },
    onRemAccount(val) {
      this.$nextTick(() => {
        this.onRemData("account", val);
      });
    },
    onRemData(key, val) {
      const data = window.localStorage.getItem(key);
      if (val) {
        const newData = this.ruleForm[key];
        if (newData !== data) {
          window.localStorage.setItem(key, newData);
        }
      } else {
        window.localStorage.setItem(key, "");
      }
    },

    onInit() {
      this.ruleForm.companyName = window.localStorage.getItem("companyName");
      this.ruleForm.account = window.localStorage.getItem("account");
    },

    onRegister() {
      this.$router.push("/register");
    },
    onResetPassword() {
      this.$router.push("/resetPassword");
    },
  },
};
</script>
<style lang="scss">
.login-box-par {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-wechat {
  width: 200px;
  transform: translate(10px, 0);
  img {
    width: 100%;
  }
}
.login-box {
  height: 100%;
  width: 480px;
  flex-shrink: none;

  .el-input--prefix .el-input__inner {
    padding-left: 30px;
  }

  .el-input .el-input__clear {
    width: auto;
    line-height: 40px !important;
  }

  .login-button {
    margin-top: 15px;

    .el-form-item__content {
      margin-bottom: 0;
    }
  }

  .login-checkbox {
    position: relative;
    .el-checkbox__label {
      color: #999999;
      font-size: 12px;
    }
  }

  // 调整组件样式
  .el-form-item__content {
    line-height: 30px;
    margin-bottom: 10px;
  }
}

.tip-error {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  font-size: 14px;
  text-align: left;
  color: red;

  label {
    font-weight: normal;
  }
}

.login-box {
  background: #fff;
  // width: 30vw;
  // width: 400px;
  height: 430px;
  padding: 24px;
  box-shadow: 1px 1px 1px 1px #ededed;
  border-radius: 5px;

  .login-tabs-box {
    font-size: 23px;
    line-height: 60px;

    & > div {
      cursor: pointer;
    }
  }
}

.forget-pwd {
  line-height: 20px;
  text-align: right;
}

.image-code-box {
  width: 100px;
  height: 34px;
  overflow: hidden;
  padding: 1px 0px;
  margin-right: -4px;
  cursor: pointer;

  img {
    height: inherit;
    width: inherit;
  }
}

.image-code-input input.el-input__inner {
  padding-right: 100px;
}

.active_tab {
  color: #1890ff;
}

.login-input-suffix {
  line-height: 36px;
  margin-right: 5px;
}

.submit-login {
  width: 100%;
  background: #e70;
}

.split {
  margin: 0 7px;
  font-weight: 100;
}

.login-label {
  color: #999;
}

.el-form-item {
  margin-bottom: 2px;
}

.p-login_register {
  display: inline-block;
  position: absolute;
  right: 0;
  color: #999999;
  font-size: 12px;
  cursor: pointer;
}
.p-login_resetPassword {
  display: inline-block;
  position: absolute;
  right: 60px;
  color: #999999;
  font-size: 12px;
  cursor: pointer;
}
.qr-code-box {
    background-color: #e70;
    width: 132px;
    height: 132px;
    display: inline-block;
    position: relative;
    text-align: center;
    div{
      background-color: #fff;
      display: inline-block;
      width: 128px;
      height: 128px;
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: 1;
    }
}
.qr-code-box::before{
  content: "";
  display: inline-block;
  width: 76px;
  background-color: #fff;
  height: 132px;
}
.qr-code-box::after{
  content: "";
  display: inline-block;
  width: 132px;
  background-color: #fff;
  height: 76px;
  position: absolute;
  left: 0;
  top: 28px;
}
.login-box-account {
  width: 275px;
  float: left;
}
.login-box-wechat{
  float: left;
  margin-top: 70px;
  margin-left: 15px;
  .wx-login-word{
    margin-top: 8px;
    background: url(https://open.weixin.qq.com/zh_CN/htmledition/res/assets/res-design-download/icon32_wx_logo.png) 50%;
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 30px;
    height: 25px;
    background-size: 25px 25px;
    margin-left: 10px;
    line-height: 24px;
  }
}
</style>
